<template>
  <div class="home">
    <navContainer />
    <div class="w">
      <div class="container serve" style="margin-top: 71px">
        <p class="english-title">LEGAL SERVICE</p>
        <h2>专业法律服务</h2>

        <ul class="serve-item clearfix">
          <li>
            <img src="@/assets/images/law-1.png" alt="">
            <div class="title">优法顾问</div>
            <p class="content">优法顾问提供在线极速咨询服务，法律顾问团队全天候在线，实时为您答疑解惑。</p>
          </li>
          <li>
            <img src="@/assets/images/law-2.png" alt="">
            <div class="title">律师函定制</div>
            <p class="content">专业、高质量的律师函可以进行法律评价和风险估计，对送达对象晓之以法律事实，维护自身合法权益。</p>
          </li>
          <li>
            <img src="@/assets/images/law3.png" alt="">
            <div class="title">优法诉讼</div>
            <p class="content">优法诉讼为企业智能匹配行业精英律师，提供全面法律诉讼服务。</p>
          </li>
          <li>
            <img src="@/assets/images/law-4.png" alt="">
            <div class="title">优法合同</div>
            <p class="content">每一份合同模板均由法律专业人士精心设计，云端存储，供用户随时随地便捷下载使用。</p>
          </li>
        </ul>
      </div>

      <div class="container product">
        <p class="english-title">FUNCTION</p>
        <h2>产品与优势</h2>

        <ul class="product-item clearfix">
          <li class="left">
            <img class="icon" src="@/assets/images/chanpin-1.png" />
            <div class="content">
              <p>优法学院</p>
              <p>提供热门法律讲座视频，法律知识，法律案例等学习资料。</p>
            </div>
          </li>
          <li class="left">
            <img class="icon" src="@/assets/images/chanpin-2.png" />
            <div class="content">
              <p>优法培训</p>
              <p>为企业提供法律培训云平台。优法盾邀请行业大咖对企业员工提供专业的法律知识讲座，如知识产权保护、保密协议等。</p>
            </div>
          </li>
          <li class="left">
            <img class="icon" src="@/assets/images/chanpin-3.png" />
            <div class="content">
              <p>平台构成</p>
              <p>优法盾由平台端、企业端和律师端三位一体构成，六大功能，实时咨询，企业端应有尽有，精准法律服务全方位覆盖。</p>
            </div>
          </li>
          <li class="left">
            <img class="icon" src="@/assets/images/chanpin-4.png" />
            <div class="content">
              <p>合作与推广</p>
              <p>优法盾不仅包括双端app，还拥有更为便捷的微信小程序。线上合作渠道包括微信公众号、快手、抖音、各大搜索引擎等，线下更有展销会、代理商等多样化宣传推广。</p>
            </div>
          </li>
        </ul>
      </div>

      <div class="container serve vip">
        <p class="english-title">MEMBER</p>
        <h2>VIP尊享</h2>

        <ul class="vip-item serve-item clearfix">
          <li>
            <img src="@/assets/images/vip-1.png" alt="">
            <div class="title">免费在线咨询</div>
            <p class="content">每一位VIP客户，都拥有不限制时长与次数的在线免费咨询特权。细致、耐心、专业，为您答疑解惑。</p>
          </li>
          <li>
            <img src="@/assets/images/vip-2.png" alt="">
            <div class="title">数万合同下载</div>
            <p class="content">优法盾数据库为VIP客户开放数万份各类合同模板免费下载，让撰写合同不再成为您的困扰</p>
          </li>
          <li>
            <img src="@/assets/images/vip-3.png" alt="">
            <div class="title">法律工具使用</div>
            <p class="content">优法盾VIP客户特享所有法律工具免费使用，法条查询、费用计算、证据评估，一键轻松搞定。</p>
          </li>
          <li>
            <img src="@/assets/images/vip-4.png" alt="">
            <div class="title">合同审核定制</div>
            <p class="content">成为我们的VIP客户，即可享受专业律师一对一的合同审核与定制，规避风险，免除后顾之忧</p>
          </li>
          <li>
            <img src="@/assets/images/vip-6.png" alt="">
            <div class="title">专属客户经理</div>
            <p class="content">VIP用户尊享专属客户经理跟进服务，案件进程、律所对接，交给我们就行。</p>
          </li>
          <li>
            <img src="@/assets/images/vip-5.png" alt="">
            <div class="title">专属法律客服</div>
            <p class="content">为每位VIP客户配置专属法务客服，全过程给予客户细致专属咨询服务。</p>
          </li>
          <li>
            <img src="@/assets/images/vip-7.png" alt="">
            <div class="title">法律文书代写</div>
            <p class="content">优法盾VIP客户特享律师函与诉讼文书限免代写，严正通知警告、起诉应诉，交给我们吧。</p>
          </li>
          <!-- <li>
            <img src="@/assets/images/vip-8.png" alt="">
            <div class="title">商标注册</div>
            <p class="content">VIP用户尊享限免商标注册申请权益，保护您的知识产权，我们是专业的。</p>
          </li> -->
        </ul>
      </div>

      <div class="container">
        <p class="english-title">ABOUT</p>
        <h2>公司简介</h2>

        <div class="container abstract">
          <div class="content">
            <p>京航网络科技集团有限公司是一家为企业赋能的高新技术企业，集团于2017年成立于中国园林之城-苏州。</p>
            <p>修改为“在建设’数字中国’的国家方针下，京航集团组建了一支有专业、有激情、有梦想的团队，以保险、法律服务、人力资源、生产制造等为目标行业，研发互联网SAAS平台。</p>
            <p>京航集团以诚信求生存，以服务求发展，秉承“专业、 专注、专心”的经营理念，以科技赋能企业，助推企业实现品质发展。</p>
            <div class="more" style="margin-top:44px">了解更多</div>
          </div>
          <img src="@/assets/images/gongsijieshao.png" alt="">
        </div>
      </div>

      <div class="container news">
        <p class="english-title">NEWS</p>
        <h2>新闻动态</h2>

        <ul class="container news-item">
          <li v-for="(item, index) in newsList" :key="index" @click="handleNews(item)">
            <img :src="fileUrl + item.cover || '@/assets/images/news.png'" alt="">
            <div class="content">
              <p class="ellipsis1">{{ item.title }}</p>
              <p class="ellipsis2" v-html="item.content">{{ item.content }}</p>
            </div>
            <div class="more" @click="() => $router.push('/news')">了解更多</div>
          </li>
        </ul>
      </div>
    </div>
    <enterpriseCulture />
  </div>
</template>

<script>
import navContainer from '@/components/navContainer'
import enterpriseCulture from '@/components/enterpriseCulture'

export default {
  name: 'Home',
  components: {
    navContainer,
    enterpriseCulture
  },
  data () {
    return {
      newsList: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.$axios.get('/web/frontNewsContent/list', { params: { pageNum: 1, pageSize: 5 } }).then(res => {
        this.newsList = res.data.result.records
      })
    },
    handleNews (item) {
      this.$router.push({
        path: '/newDetail',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

.serve {
  .serve-item {
    li {
      float: left;
      width: 247px;
      height: 275px;
      border: 1px solid #EEEEEE;
      margin-right: 30px;
      box-sizing: border-box;
      padding: 32px 24px;

      img {
        width: 88px;
        height: 88px;
      }

      .title {
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #394170;
        margin: 10px 0 17px 0;
      }

      .content {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #5D6493;
        line-height: 24px;
      }
    }

    li:last-child {
      margin: 0;
    }
  }
}

.product {
  .product-item {
    li {
      width: 524px;
      height: 180px;
      background: rgba(15,25,49,0.22) no-repeat;
      background-size: 100% 100%;
      margin-right: 32px;
      position: relative;

      .icon {
        width: 72px;
        height: 72px;
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
      }

      .content {
        position: absolute;
        top: 40px;
        left: 131px;
        padding-right: 30px;
        box-sizing: border-box;

        p:nth-child(1) {
          font-size: 20px;
          color: #FFFFFF;
          letter-spacing: 0;
        }

        p:nth-child(2) {
          margin-top: 10px;
          font-size: 14px;
          color: #FFFFFF;
        }
      }
    }

    li:nth-child(n + 3) {
      margin-top: 38px;
    }

    li:nth-child(2n) {
      margin-right: 0;
    }

    li:nth-child(1) {
      background-image: url('../assets/images/backgound1.png');
    }

    li:nth-child(2) {
      background-image: url('../assets/images/backgound2.png');
    }

    li:nth-child(3) {
      background-image: url('../assets/images/backgound3.png');
    }

    li:nth-child(4) {
      background-image: url('../assets/images/backgound4.png');
    }
  }
}

.vip {
  .vip-item {
    li {
      border: none;
      background: #EEF5FF;
      padding: 30px 20px;
      width: 255px;
      height: 226px;
      margin-right: 20px;

      img {
        width: 50px;
        height: 50px;
      }

      .title {
        font-size: 18px;
        color: #394170;
        margin: 20px 0 18px 0;
      }

      .content {
        font-size: 12px;
        color: #5D6493;
        line-height: 18px;
      }
    }

    li:nth-child(n + 5) {
      margin-top: 20px;
    }

    li:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.abstract {
  display: flex;

  .content {
    flex: 1;
    font-size: 16px;
    color: #394170;
    line-height: 30px;
    padding-right: 30px;

    p {
      margin-top: 20px;
    }

    p:nth-child(1) {
      text-indent: 2em;
    }
  }

  .img {
    width: 500px;
    height: 60px;
  }
}

.news {
  margin-bottom: 40px;
  .news-item {
    li {
      display: flex;
      padding: 40px 30px 40px 40px;

      img {
        width: 230px;
        height: 130px;
      }

      .content {
        flex: 1;
        margin-left: 20px;

        p:nth-child(1) {
          font-size: 18px;
          color: #394170;
        }

        p:nth-child(2) {
          margin-top: 13px;
          font-size: 12px;
          color: #5D6493;
        }
      }

      // .more {
      //   display: none;
      // }
    }
  }
}

@media screen and (max-width: 1000px) {
  .product {
    .product-item {
      li {
        width: 100%;
        margin-top: 38px;
      }

      li:nth-child(1) {
        margin: 0;
      }
    }
  }

  .serve {
    .serve-item {
      li {
        width: 100%;
        margin: 0;
        margin-bottom: 16px;
      }
    }
  }

  .vip {
    .vip-item {
      li {
        width: 50%;
      }
      li:nth-child(n) {
        margin: 0;
      }
    }
  }

  .abstract {
    flex-direction: column;
  }

  .news {
    .news-item {
      li {
        padding: 16px;
        img {
          width: 115px;
          height: 65px;
        }

        .content {
          p:nth-child(2) {
            margin-top: 10px;
          }
        }

        .more {
          margin: auto 0;
          display: none;
        }
      }
    }
  }
}

.more {
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #FFFFFF;
  border: 1px solid #2B68E5;
  font-size: 14px;
  color: #2B68E5;
  cursor: pointer;
}
</style>
