<template>
  <div class="regards">
    <div class="header-container"></div>

    <div class="w">
      <div class="company">
        <div class="title">
          <div />
          关于我们
        </div>
        <h2 class="company-name">京航网络科技集团有限公司</h2>
        <p style="margin-top: 30px">
          京航网络科技集团有限公司是一家为企业赋能的高新技术企业，集团于2017年成立于中国园林之城-苏州。
        </p>
        <p>
          在建设“数字中国”的国家方针下，京航集团组建了一支有专业、有激情、有梦想的团队，以保险、人力资源、生产制造为目标行业，研发互联网SAAS平台。
        </p>
        <p>
          京航集团以诚信求生存，以服务求发展，秉承“专业、
          专注、专心”的经营理念，以科技赋能企业，助推企业实现品质发展。
        </p>
      </div>

      <div class="container nature-of-business">
        <p class="english-title">NATURE OF BUSINESS</p>
        <h2>经营范围</h2>

        <p class="subhead">
          京航集团坚持以科技为本，在探索ToB领域过程中，依托AI、大数据、安全、IoT等技术能力，京航将这些经验和成果固化下来，形成面向各行各业的产品和行业解决方案，助力实现“万物智联”。
        </p>
        <ul class="product-item clearfix">
          <li class="left">
            <img class="icon" src="@/assets/images/fanwei-1.png" />
            <div class="content">
              <p>企业财产险领域</p>
              <p>
                在企业财产险领域，京航集团打造企联保保险科技平台，为企业主解决用工风险，为保险公司创造业绩。
              </p>
            </div>
          </li>
          <li class="left">
            <img class="icon" src="@/assets/images/fanwei-2.png" />
            <div class="content">
              <p>企业经营领域</p>
              <p>
                在企业经营领域，京航集团打造优法盾法律服务平台，为每个企业建立法律顾问团队，规避经营风险。
              </p>
            </div>
          </li>
          <li class="left">
            <img class="icon" src="@/assets/images/fanwei-3.png" />
            <div class="content">
              <p>人力资源行业</p>
              <p>
                在人力资源行业，京航集团打造打造多样化用工时代的全流程劳动力管理系统。
              </p>
            </div>
          </li>
          <li class="left">
            <img class="icon" src="@/assets/images/fanwei-4.png" />
            <div class="content">
              <p>工业制造领域</p>
              <p>
                工业制造领域，一方面，通过云计算、大数据、人工智能等技术，推动企业进行数字化升级，提高效率；另一方面，在积极帮助企业聚能云端，打造行业创新转型生态体系，推动工业制造业迈向智能制造时代。
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="boss-abstract">
      <div class="w">
        <div class="banner-index">
          <div class="content">
            <h2>陈能军</h2>
            <p>创始人</p>
            <ul>
              <li>20年创业经验</li>
              <li>5年企业管理服务经验，白手起家，从0做到有20家控股公司</li>
              <li>一级人力资源管理师、一级劳动关系协调师</li>
              <li>清华大学在读MBA</li>
            </ul>
            <ul>
              <li class="left">专业</li>
              <li class="left">激情</li>
              <li class="left">梦想</li>
            </ul>
          </div>
          <img src="@/assets/images/chuangshiren.png" alt="" />
        </div>
      </div>
    </div>

    <div class="w">
      <div class="container honor">
        <p class="english-title">HONOR</p>
        <h2>企业荣誉</h2>

        <ul class="clearfix">
          <li class="left">
            <img src="@/assets/images/check.png" alt="">以常熟为总部，全国建设20家以上的分支机构。
          </li>
          <li class="left">
            <img src="@/assets/images/check.png" alt="">成为人保、安联、平安险企的重要合作伙伴。
          </li>
          <li class="left">
            <img src="@/assets/images/check.png" alt="">自主研发企联保保险科技平台，上市首年突破1亿保费规模。
          </li>
          <li class="left">
            <img src="@/assets/images/check.png" alt="">自主研发打造优法盾法律服务平台，多平台渠道合作推广。
          </li>
          <li class="left">
            <img src="@/assets/images/check.png" alt="">国家高新技术认证企业，坚持以科技赋能企业，通过持续创新推动行业转型升级。
          </li>
        </ul>
      </div>
    </div>
    <enterpriseCulture />
  </div>
</template>

<script>
import enterpriseCulture from '@/components/enterpriseCulture'
export default {
  name: 'Regards',
  components: {
    enterpriseCulture
  },
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
.regards {
  .company {
    height: 305px;
    background: #ffffff;
    box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.14);
    padding: 40px 30px;
    box-sizing: border-box;
    margin-top: -164px;

    .title {
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #394170;

      div {
        width: 40px;
        height: 20px;
        background-image: linear-gradient(-270deg, #4981fc 0%, #ffffff 100%);
        margin-right: 10px;
      }
    }

    .company-name {
      font-size: 38px;
      color: #394170;
      margin-top: 10px;
    }

    p {
      font-size: 14px;
      color: #394170;
      margin-top: 10px;
    }
  }

  .nature-of-business {
    h2 {
      margin-bottom: 28px;
    }

    .subhead {
      font-size: 14px;
      color: #394170;
      line-height: 24px;
      text-indent: 2em;
    }

    .product-item {
      li {
        width: 524px;
        height: 178px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        margin: 30px 30px 0 0;
        display: flex;
        align-items: center;
        padding: 0 24px;
        box-sizing: border-box;

        .icon {
          width: 88px;
          height: 88px;
          margin-right: 24px;
        }

        .content {
          box-sizing: border-box;

          p:nth-child(1) {
            font-size: 18px;
            color: #394170;
            letter-spacing: 0;
          }

          p:nth-child(2) {
            margin-top: 10px;
            font-size: 14px;
            color: #5d6493;
            letter-spacing: 0;
            line-height: 24px;
          }
        }
      }
      li:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .boss-abstract {
    width: 100%;
    height: 486px;
    background: url("../assets/images/chuanshiren-backgound.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 60px;
    .w {
      position: relative;
      height: 100%;
    }

    .banner-index {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      display: flex;

      .content {
        width: 466px;
        color: #fff;

        h2 {
          font-size: 30px;
          margin-top: 82px;
        }

        p:nth-child(2) {
          font-size: 20px;
          margin-top: 14px;
        }

        ul:nth-child(3) {
          margin-top: 30px;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 34px;
        }

        ul:nth-child(3) li {
          position: relative;
          text-indent: 1em;
        }

        ul:nth-child(3) li::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #fff;
        }

        ul:nth-child(4) li {
          margin: 46px 40px 0 0;
        }
      }

      img {
        margin: 0 70px 0 auto;
      }
    }
  }

  .honor {
    margin-bottom: 60px;
    ul {
      li {
        display: flex;
        align-items: center;
        width: 50%;
        height: 90px;
        font-size: 16px;
        color: #394170;
        line-height: 30px;
        padding: 0 30px 0 24px;
        box-sizing: border-box;

        img {
          width: 24px;
          height: 24px;
          margin-right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .regards {
    .company {
      height: auto;

      .company-name {
        font-size: 28px;
      }
    }

    .nature-of-business {
      .product-item {
        li {
          width: 100%;
        }
      }
    }

    .boss-abstract {
      height: auto;
      .banner-index {
        position: relative;
        flex-direction: column;
        padding: 0 20px;
        box-sizing: border-box;

        .content {
          width: 100%;

          ul:nth-child(3) li {
            font-size: 12px;
          }
        }

        img {
          width: 300px;
          height: 300px;
        }
      }
    }

    .honor {
      ul {
        li {
          width: 100%;
        }
      }
    }
  }
}
</style>
