<template>
  <div class="news">
    <div class="header-container"></div>

    <div class="w">
      <div class="news-content">
        <div class="title">
          <div />
          新闻资讯
        </div>
        <!-- <ul class="tabs clearfix">
          <li
            v-for="item in tabsList"
            :key="item.id"
            :class="queryParams.newsCategoryId === item.id ? 'left check' : 'left'"
            @click="handleTabs(item)"
          >
            {{ item.name }}
          </li>
        </ul> -->
        <div style="padding: 30px 40px 0 40px">
          <el-tabs v-model="queryParams.newsCategoryId" @tab-click="getList">
            <el-tab-pane v-for="(item, index) in tabsList" :key="index" :label="item.name" :name="item.id + ''"></el-tab-pane>
          </el-tabs>
        </div>
        <ul class="news-list">
          <li v-for="(item, index) in newsList" :key="index" @click="handleDetails(item)">
            <img :src="fileUrl + item.cover || '@/assets/images/news.png'" alt="" />
            <div class="content">
              <p class="ellipsis1">{{ item.title }}</p>
              <p class="ellipsis2">
                {{ escapeHTML(item.content) }}
              </p>
              <div class="detail" @click="handleDetails(item)">查看详情</div>
            </div>
            <div class="date">{{ item.created }}</div>
          </li>
        </ul>
        <div class="right">
          <el-pagination
            :page-size="queryParams.pageSize"
            layout="total, prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <pagination /> -->
    </div>
  </div>
</template>

<script>
// import pagination from '../components/pagination'
export default {
  name: 'News',
  components: {
    // pagination
  },
  data () {
    return {
      tabsList: [],
      current: 1,
      newsList: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        newsCategoryId: ''
      }
    }
  },
  created () {
    this.handleNewsType()
  },
  methods: {
    escapeHTML (untrusted) {
      return untrusted
        .replace(/<(?:.|\n)*?>/gm, '')
        .replace(/(&rdquo;)/g, '')
        .replace(/&ldquo;/g, '')
        .replace(/&mdash;/g, '-')
        .replace(/&nbsp;/g, '')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/<[\w\s"':=/]*/, '')
    },
    handleTabs (item) {
      this.queryParams.newsCategoryId = item.id
      this.getList()
    },
    handleCurrentChange (val) {
      this.queryParams.pageNum = val
      this.getList()
    },
    handleDetails (item) {
      this.$router.push({
        path: '/newDetail',
        query: {
          id: item.id
        }
      })
    },
    getList () {
      this.$axios.get('/web/frontNewsContent/list', { params: this.queryParams }).then(res => {
        this.newsList = res.data.result.records
        this.total = res.data.result.total
      })
    },
    handleNewsType () {
      this.$axios.get('/web/frontNewsCategory/list').then(res => {
        this.tabsList = res.data.result
        this.queryParams.newsCategoryId = res.data.result[0].id + ''
        this.getList()
      })
    }
  }
}
</script>

<style scoped lang="less">
.news {
  .news-content {
    padding: 40px 0;
    background: #ffffff;
    box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.14);
    margin: -164px auto 58px auto;
    min-height: 200px;

    .title {
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #394170;
      margin-left: 30px;

      div {
        width: 40px;
        height: 20px;
        background-image: linear-gradient(-270deg, #4981fc 0%, #ffffff 100%);
        margin-right: 10px;
      }
    }

    .tabs {
      font-size: 18px;
      color: #394170;
      margin-top: 6px;
      border-bottom: 1px solid #eeeeee;
      padding-left: 30px;

      li {
        height: 54px;
        line-height: 54px;
        margin-right: 50px;
        cursor: pointer;
      }

      .check {
        border-bottom: 2px solid #3774fc;
        color: #3774fc;
      }
    }

    /deep/.el-tabs__header {
      margin: 0;
    }
    /deep/.el-tabs__item {
      height: 53px;
      font-size: 18px;
      color: #394170;
    }

    .news-list {
      li {
        display: flex;
        padding: 40px 30px 40px 40px;

        img {
          width: 230px;
          height: 130px;
        }

        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          p:nth-child(1) {
            font-size: 18px;
            color: #394170;
          }

          p:nth-child(2) {
            margin-top: 13px;
            font-size: 12px;
            color: #5d6493;
          }

          .detail {
            margin-top: auto;
            font-size: 14px;
            color: #3774FC;
            cursor: pointer;
          }
        }

        .date {
          margin-top: auto;
          font-size: 14px;
          color: #919498;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .news {
    .w {
      .tabs {
        width: 100%;
        font-size: 16px;
        overflow: hidden;
      }
      .news-list {
        li {
          display: flex;
          padding: 20px 30px 20px 40px;

          img {
            width: 115px;
            height: 65px;
          }

          .content {
            .detail {
              display: none;
            }
          }
          .date {
            display: none;
          }
        }
      }
    }
  }
}
</style>
