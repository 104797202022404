<template>
  <div class="course">
    <div class="header-container"></div>
    <div class="w">
      <div class="course-content">
      <div class="mobile-down">
        <div class="title">
          <div />
          移动端下载
          <span>鼠标移入即可扫码下载</span>
        </div>

        <ul class="down-icon clearfix">
          <li class="left">
            <img class="ios-down-ercode" src="@/assets/images/ios.png" alt="">
            <img class="ios-down" src="@/assets/images/pingguo.png" alt="" style="width:102px;height:120px">
            <p>ios</p>
            <div class="download" @click="handleOpen('https://apps.apple.com/cn/app/%E4%BC%98%E6%B3%95%E7%9B%BE/id1605508216')">免费下载</div>
            <div class="versions">版本: 1.1.21</div>
          </li>
          <li class="left">
            <!-- <div class="android-down">123</div> -->
            <img class="android-down-ercode" src="@/assets/images/android.png" alt="" style="border-radius: 12px;">
            <img class="android-down" src="@/assets/images/anzhuo.png" alt="" style="width:60px;height:120px;">
            <p>Android</p>
            <div class="download" @click="handleOpen('https://statics-1307609838.cos.ap-shanghai.myqcloud.com/setup/yfd.apk')">免费下载</div>
            <div class="versions">版本: 1.1.21</div>
          </li>
          <li class="left">
            <img class="xcx-ercode" src="@/assets/images/xcx-ercode.jpg" alt="">
            <img class="xcx" src="@/assets/images/xcx.png" alt="" style="width:60px;height:120px">
            <p>小程序</p>
            <div class="download">扫码进入</div>
            <div class="versions">版本: 1.1.21</div>
          </li>
        </ul>
      </div>

      <div class="container use-guide">
        <p class="english-title">USE GUIDE</p>
        <h2>使用指南</h2>

        <div class="banner">
          <div class="describe">
            <h3>登录与首页</h3>
            <p>进入app或小程序，选择去登录，点击一键登录，授权权限信息即可。首页可查看优法盾一系列相关服务。</p>
            <div class="app-detail">
              <div class="app-detail-item">
                <div>最新版本: 1.1.21</div>
                <div>应用名称: 优法盾</div>
              </div>
              <div class="app-detail-item">
                <div>更新时间: 2023年2月13日</div>
                <div>开 发 者: 京航网络科技集团有限公司</div>
              </div>
            </div>
            <div class="privacy">
              <div @click="handleOpen('https://statics-1307609838.cos.ap-shanghai.myqcloud.com/app/%E9%9A%90%E7%A7%81.html')">隐私政策</div>
              <div @click="handleOpen('https://statics-1307609838.cos.ap-shanghai.myqcloud.com/app/%E6%9D%83%E9%99%90.html')">申请权限</div>
            </div>
          </div>
        </div>

        <ul class="vip-item serve-item clearfix">
          <li class="left">
            <img src="@/assets/images/vip-1.png" alt="">
            <div class="title">免费在线咨询</div>
            <p class="content">每一位VIP客户，都拥有不限制时长与次数的在线免费咨询特权。细致、耐心、专业，为您答疑解惑。</p>
          </li>
          <li class="left">
            <img src="@/assets/images/vip-2.png" alt="">
            <div class="title">数万合同下载</div>
            <p class="content">优法盾数据库为VIP客户开放数万份各类合同模板免费下载，让撰写合同不再成为您的困扰</p>
          </li>
          <li class="left">
            <img src="@/assets/images/vip-3.png" alt="">
            <div class="title">法律工具使用</div>
            <p class="content">优法盾VIP客户特享所有法律工具免费使用，法条查询、费用计算、证据评估，一键轻松搞定。</p>
          </li>
          <li class="left">
            <img src="@/assets/images/vip-4.png" alt="">
            <div class="title">合同审核定制</div>
            <p class="content">成为我们的VIP客户，即可享受专业律师一对一的合同审核与定制，规避风险，免除后顾之忧</p>
          </li>
          <li class="left">
            <img src="@/assets/images/vip-6.png" alt="">
            <div class="title">专属客户经理</div>
            <p class="content">VIP用户尊享专属客户经理跟进服务，案件进程、律所对接，交给我们就行。</p>
          </li>
          <li class="left">
            <img src="@/assets/images/vip-5.png" alt="">
            <div class="title">专属法律客服</div>
            <p class="content">为每位VIP客户配置专属法务客服，全过程给予客户细致专属咨询服务。</p>
          </li>
          <li class="left">
            <img src="@/assets/images/vip-7.png" alt="">
            <div class="title">法律文书代写</div>
            <p class="content">优法盾VIP客户特享律师函与诉讼文书限免代写，严正通知警告、起诉应诉，交给我们吧。</p>
          </li>
          <!-- <li class="left">
            <img src="@/assets/images/vip-8.png" alt="">
            <div class="title">商标注册</div>
            <p class="content">VIP用户尊享限免商标注册申请权益，保护您的知识产权，我们是专业的。</p>
          </li> -->
        </ul>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Course',
  components: {
  },
  data () {
    return {}
  },
  methods: {
    download (data, fileName) {
      if (!data) {
        return
      }
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.id = 'Adownload'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.getElementById('Adownload').remove()
    },
    handleOpen (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped lang="less">
.course {
  .course-content {
    background: #ffffff;
    margin: -164px auto 60px auto;
    min-height: 200px;

    .mobile-down {
      background: #FFFFFF;
      box-shadow: 4px 4px 12px 0 rgba(0,0,0,0.04);
      padding: 40px 30px;

      .down-icon {
        margin-top: 40px;
        li {
          // display: flex;
          // align-items: center;
          // justify-content: center;
          text-align: center;
          width: 301px;
          height: 380px;
          background: #FFFFFF;
          border: 2px solid #EEEEEE;
          margin-right: 58px;
          box-sizing: border-box;
          padding: 40px 0;

          img {
            width: 120px;
            height: 120px;
          }

          // .android-down-ercode {
          //   display: none;
          // }
          p {
            font-size: 24px;
            color: #394170;
            margin-top: 40px;
          }

          .download {
            width: 150px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #FFFFFF;
            border: 1px solid #2B68E5;
            font-size: 14px;
            color: #2B68E5;
            margin: 40px auto 0 auto;
            cursor: pointer;
          }

          .download:hover {
            background: #2B68E5;
            color: #fff;
          }

          .versions {
            font-size: 12px;
            color: #909090;
            margin-top: 10px;
          }
        }

        li:nth-child(3) {
          margin: 0;
        }

        li:hover {
          border: 2px solid #2B68E5;
        }

        .android-down-ercode,
        .ios-down-ercode,
        .xcx-ercode {
          display: none;
        }

        li:nth-child(1):hover {
          .ios-down-ercode {
            display: inline-block;
          }

          .ios-down {
            display: none;
          }
        }

        li:nth-child(2):hover {
          .android-down-ercode {
            display: inline-block;
          }

          .android-down {
            display: none;
          }
        }

        li:nth-child(3):hover {
          .xcx-ercode {
            display: inline-block;
          }

          .xcx {
            display: none;
          }
        }

      }
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #394170;
      margin-left: 30px;

      div {
        width: 40px;
        height: 20px;
        background-image: linear-gradient(-270deg, #4981fc 0%, #ffffff 100%);
        margin-right: 10px;
      }

      span {
        opacity: 0.74;
        font-size: 17px;
        color: #394170;
        margin-left: 17px;
      }
    }

    .use-guide {
      li {
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        padding: 30px 20px;
        width: 255px;
        height: 226px;
        margin-right: 20px;
        box-sizing: border-box;

        img {
          width: 50px;
          height: 50px;
        }

        .title {
          font-size: 18px;
          color: #394170;
          margin: 20px 0 18px 0;
        }

        .content {
          font-size: 12px;
          color: #5D6493;
          line-height: 18px;
        }
      }

      li:nth-child(n + 5) {
        margin-top: 20px;
      }

      li:nth-child(4n) {
        margin-right: 0;
      }

      .banner {
        position: relative;
        height: 505px;
        background: url('../assets/images/employ.png') no-repeat;
        background-size: 100% 100%;
        margin-bottom: 26px;

        .describe {
          position: absolute;
          left: 167px;
          top: 63px;

          h3 {
            opacity: 0.8;
            font-size: 36px;
            color: #394170;
          }

          p {
            opacity: 0.58;
            width: 403px;
            font-size: 16px;
            color: #394170;
            line-height: 24px;
            margin-top: 29px;
          }

          .app-detail {
            border-top: 1px solid #EEEEEE;
            border-bottom: 1px solid #EEEEEE;
            padding: 20px 0;
            margin-top: 20px;
            .app-detail-item {
              display: inline-block;
              opacity: 0.58;
              font-size: 12px;
              color: #394170;
              line-height: 24px;
            }

            .app-detail-item:nth-child(1) {
              border-right: 1px solid #EEEEEE;
              margin-right: 12px;
              padding-right: 12px;
            }
          }

          .privacy {
            padding: 12px 0;
            div {
              display: inline-block;
              opacity: 0.58;
              font-size: 12px;
              color: #394170;
              cursor: pointer;
            }

            div:nth-child(1) {
              padding-right: 16px;
              border-right: 1px solid #EEEEEE;
            }

            div:nth-child(2) {
              padding-left: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .course {
    .w {
      .mobile-down {
        .title {
          span {
            display: none;
          }
        }
      }

      .use-guide {
        .banner {
          display: none;
        }
        .serve-item {
          li {
            width: 50%;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
