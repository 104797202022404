<template>
<div class="header-container">
  <div class="w">
    <!-- <div class="header-nav clearfix">
      <img class="logo left" src="@/assets/images/logo.png" alt="">
      <ul class="right">
        <li class="left">首页</li>
        <li class="left">关于我们</li>
        <li class="left">新闻咨询</li>
        <li class="left">使用指南</li>
      </ul>
    </div> -->
    <!-- <tabs /> -->

    <div class="banner-index">
      <div class="content">
        <h1>优法盾</h1>
        <p>专业法律服务 为中小企业保驾护航</p>
        <p>优法盾创新推出线上极速法律咨询 + 线下一站式律所对接，规避经营风险、应对诉讼纠纷。</p>
        <p>VIP服务费：<span>4688元</span>/年  <span style="margin-left: 20px;">9988元</span>/3年</p>
        <div class="download" @click="handleDown">立即下载, <span>免费试用</span></div>
      </div>
      <img src="@/assets/images/banner-index.png" alt="">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'NavContainer',
  components: {
  },
  data () {
    return {}
  },
  created () {
  },
  methods: {
    handleDown () {
      this.$router.push('/course')
    }
  }
}
</script>

<style scoped lang="less">
.header-container {
  width: 100%;
  height: 540px;
  background: url('../assets/images/banner1.png') no-repeat;
  background-size: 100% 100%;
}

.header-container .w {
  position: relative;
  height: 100%;
}

.header-container .header-nav {
  padding-top: 23px;
}

.header-container .header-nav li {
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  margin-left: 30px;
}

.banner-index {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;

  .content {
    width: 466px;
    color: #fff;

    h1 {
      font-size: 48px;
      margin-top: 50px;
    }

    p:nth-child(2) {
      font-size: 22px;
      margin-top: 10px;
    }

    p:nth-child(3) {
      font-size: 14px;
      line-height: 24px;
      border-top: 1px solid rgba(255, 255, 255, .2);
      padding-top: 13px;
      margin-top: 13px;
    }

    p:nth-child(4) {
      font-size: 16px;
      margin-top: 10px;
    }

    .download {
      width: 180px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 14px;
      background: #2979FF;
      box-shadow: 2px 2px 6px 0 #297AFF;
      border-radius: 25.5px;
      margin-top: 17px;
      cursor: pointer;
    }
  }

  img {
    margin-left: auto;
  }

}

@media screen and (max-width: 1000px) {
  .header-container {
    height: 400px;
    padding: 0 16px;
    .banner-index {
      bottom: 16px;
      .content {
        width: 100%;
      }
      img {
        display: none;
      }
    }
  }
}
</style>
