<template>
  <div>
    <div class="w">
      <div class="header-nav left">
        <img class="logo left" src="@/assets/images/logo.png" alt="" style="cursor: pointer;" @click="handleHome">
        <ul class="right">
          <li v-for="(item, index) in tabsList" :key="index" :class="current === item.value ? 'check left' : 'left'" @click="handlePath(item)">{{ item.label }}</li>
        </ul>
      </div>
    </div>
    <router-view />
    <footerContainer />
  </div>
</template>

<script>
import footerContainer from './footerContainer'
export default {
  name: 'layout',
  components: {
    footerContainer
  },
  data () {
    return {
      tabsList: [
        { label: '首页', value: 1, path: '/home' },
        { label: '关于我们', value: 2, path: '/regards' },
        { label: '新闻资讯', value: 3, path: '/news' },
        { label: '使用指南', value: 4, path: '/course' }
      ],
      current: 1
    }
  },
  methods: {
    handlePath (item) {
      console.log(item, 231)
      this.current = item.value
      this.$router.push(item.path)
    },
    handleHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="less">

.header-nav {
  position: absolute;
  width: 1080px;
  padding-top: 23px;
  z-index: 2022;
}

.header-nav li {
  font-size: 14px;
  color: #FFFFFF;
  opacity: .7;
  text-align: center;
  margin-left: 30px;
  z-index: 2022;
  cursor: pointer;
}

.header-nav .check {
  font-family: PingFangSC-Semibold;
  opacity: 1;
}

@media screen and (max-width: 1000px) {
  .w {
    padding: 0;
  }
  .header-nav {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 16px;

    .logo {
      height: 20px;
    }

    ul {
      margin-left: auto;
    }

    li {
      margin-left: 16px;
    }

    li:nth-child(1) {
      margin: 0;
    }
  }
}

</style>
