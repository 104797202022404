import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/common.css'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
axios.defaults.baseURL = 'https://yfd.qilianbao.cn/api'
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
Vue.prototype.$axios = axios
Vue.config.productionTip = false
const fileUrl = 'https://yfd-share-prod-1307609838.cos.ap-shanghai.myqcloud.com/'
Vue.prototype.fileUrl = fileUrl
Vue.use(ElementUI)

const userAgentInfo = navigator.userAgent
const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
let flag = true
for (let v = 0; v < Agents.length; v++) {
  if (userAgentInfo.indexOf(Agents[v]) > 0) {
    flag = false
    break
  }
}
if (!flag) {
  // window.location.href = 'https://yfd-h5.suzhoujinghang.com'
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
