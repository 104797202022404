<template>
  <div class="enterprise-culture-container">
    <div class="w">
      <h4>企业文化</h4>
      <p>使命、愿景、价值观</p>
      <div style="margin-top: 39px">
        <div class="tags">服务创造价值   专业赢得信任</div>
        <div class="tags">创新奋进心牵手   收获辉煌共拥有</div>
        <div class="tags">成就客户  合作共赢  诚实守信  感恩奉献  拥抱变化</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EnterpriseCulture',
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
.enterprise-culture-container {
  box-sizing: border-box;
  padding-top: 53px;
  height: 220px;
  background: url('../assets/images/qiyewenhua-backgound.png') no-repeat;
  background-size: 100% 100%;
  color: #FFFFFF;

  h4 {
    display: inline-block;
    font-size: 24px;
  }

  p {
    display: inline-block;
    opacity: 0.8;
    font-size: 18px;
    margin-left: 24px;
  }

  .tags {
    display: inline-block;
    box-sizing: border-box;
    padding: 11px 14px;
    background: rgba(255, 255, 255, 0.14);
    box-shadow: 0 2px 8px 0 rgba(6,60,167,0.08);
    border-radius: 6px;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 28px;
    margin-right: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .enterprise-culture-container {
    height: 360px;

    .tags {
      margin-top: 10px;
    }
  }
}

</style>
