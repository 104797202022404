<template>
  <div class="footer-container">
    <div class="w clearfix">
      <div class="connect-us left clearfix">
        <h4>联系我们</h4>
        <p>您与我们的每一次交流都将促进我们的成长</p>
        <p>电话： 400-876-6768</p>
        <p style="margin-top: 10px;">地址：常熟市贤士路88号1幢2楼</p>
      </div>
      <div class="follow-us left clearfix">
        <h4>关注我们</h4>
        <div class="left">
          <img src="@/assets/images/gzh.jpg" alt="">
          <p>微信关注优法盾公众号</p>
        </div>
        <div class="left">
          <img src="@/assets/images/xcx-ercode.jpg" alt="">
          <p>微信扫码进入小程序</p>
        </div>
      </div>

      <div class="copyright">
        <p class="left">Copyright © 2016 京航网络科技集团有限公司 400-876-6768 苏ICP备2021002065号 </p>
        <ul class="right">
          <li class="left">法律声明</li>
          <li class="left">隐私条款</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterContainer.',
  data () {
    return {}
  }
}
</script>

<style scoped lang="less">
.footer-container {
  position: relative;
  width: 100%;
  height: 325px;
  background: #242A32;
  font-size: 18px;
  color: #FFFFFF;
}

.footer-container .connect-us,
.footer-container .follow-us {
  width: 50%;

  img {
    width: 88px;
    height: 88px;
  }
}

.footer-container .connect-us h4,
.footer-container .follow-us h4 {
  font-size: 20px;
  color: #FFFFFF;
  margin: 61px 0 20px 0;
}

.footer-container .connect-us p:nth-child(n + 3) {
  opacity: 0.66;
  margin-top: 20px;
}

.footer-container .follow-us .left {
  width: 108px;
  text-align: center;
  margin-right: 54px;
  font-size: 12px;
  color: #FFFFFF;
}

.footer-container .follow-us .left  p {
  margin-top: 13px;
}

.copyright {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 1112px;
  height: 55px;
  opacity: 0.66;
  font-size: 12px;
  color: #FFFFFF;
  border-top: 1px solid #3F4758;
  line-height: 55px;
}

.copyright ul li {
  margin-left: 40px;
  cursor: pointer;
}

.footer-container .follow-us .left img {
  width: 108px;
  height: 108px;
}

@media screen and (max-width: 1000px) {
  .footer-container {
    height: auto;
    padding-bottom: 16px;
  }
  .footer-container .connect-us,
  .footer-container .follow-us {
    width: 100%;

    img {
      width: 88px;
      height: 88px;
    }
  }

  .footer-container .connect-us h4,
  .footer-container .follow-us h4 {
    margin-top: 30px;
  }

  .copyright {
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 16px;
    bottom: -55px;
    background: #242A32;
    opacity: 1;
    padding: 0 16px;
  }

  .footer-container .follow-us .left {
    width: 50%;
    margin: 0;
  }

  .footer-container .follow-us .left img {
    width: 90%;
    height: auto;
  }
}

</style>
