import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Regards from '../views/regards.vue'
import News from '../views/news.vue'
import Course from '../views/course.vue'
import NewDetail from '../views/newDetail.vue'
import layout from '../components/layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: layout,
    children: [
      {
        path: '/home',
        component: Home
      },
      {
        path: '/regards',
        component: Regards
      },
      {
        path: '/news',
        component: News
      },
      {
        path: '/regards',
        component: Regards
      },
      {
        path: '/course',
        component: Course
      },
      {
        path: '/newDetail',
        component: NewDetail
      }
    ]
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
