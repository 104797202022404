<template>
  <div class="new-detail">
    <div class="header-container"></div>
    <div class="w">
      <div class="new-detail-content">
        <h3 class="title">{{ detail.title }}</h3>
        <div class="date">{{ detail.created }}</div>
        <p v-html="detail.content">{{ detail.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewDetail',
  data () {
    return {
      detail: {}
    }
  },
  created () {
    console.log(this.$route.query.id)
    this.getDetails(this.$route.query.id)
  },
  methods: {
    getDetails (id) {
      this.$axios.get('/web/frontNewsContent/' + id).then(res => {
        console.log(res.data.result, 3234)
        this.detail = res.data.result
      })
    }
  }
}
</script>

<style scoped lang="less">
.new-detail {
  .new-detail-content {
    padding: 40px 30px;
    background: #ffffff;
    box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
    margin: -164px auto 58px auto;
    min-height: 200px;

    .title {
      font-size: 20px;
      color: #394170;
    }

    .date {
      font-size: 14px;
      color: #919498;
      margin: 18px 0 36px 0;
    }

    p {
      font-size: 14px;
      color: #5D6493;
      line-height: 24px;
    }
  }
}
</style>
